// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-search-page-js": () => import("./../../../src/pages/search-page.js" /* webpackChunkName: "component---src-pages-search-page-js" */),
  "component---src-templates-advertisement-disclosure-page-component-jsx": () => import("./../../../src/templates/advertisement-disclosure/page.component.jsx" /* webpackChunkName: "component---src-templates-advertisement-disclosure-page-component-jsx" */),
  "component---src-templates-best-list-component-jsx": () => import("./../../../src/templates/best/list.component.jsx" /* webpackChunkName: "component---src-templates-best-list-component-jsx" */),
  "component---src-templates-best-post-component-jsx": () => import("./../../../src/templates/best/post.component.jsx" /* webpackChunkName: "component---src-templates-best-post-component-jsx" */),
  "component---src-templates-comparison-post-component-jsx": () => import("./../../../src/templates/comparison/post.component.jsx" /* webpackChunkName: "component---src-templates-comparison-post-component-jsx" */),
  "component---src-templates-discounts-post-component-jsx": () => import("./../../../src/templates/discounts/post.component.jsx" /* webpackChunkName: "component---src-templates-discounts-post-component-jsx" */),
  "component---src-templates-home-page-page-component-jsx": () => import("./../../../src/templates/home-page/page.component.jsx" /* webpackChunkName: "component---src-templates-home-page-page-component-jsx" */),
  "component---src-templates-privacy-policy-page-component-jsx": () => import("./../../../src/templates/privacy-policy/page.component.jsx" /* webpackChunkName: "component---src-templates-privacy-policy-page-component-jsx" */),
  "component---src-templates-reviews-list-component-jsx": () => import("./../../../src/templates/reviews/list.component.jsx" /* webpackChunkName: "component---src-templates-reviews-list-component-jsx" */),
  "component---src-templates-reviews-post-component-jsx": () => import("./../../../src/templates/reviews/post.component.jsx" /* webpackChunkName: "component---src-templates-reviews-post-component-jsx" */),
  "component---src-templates-tutorials-list-component-jsx": () => import("./../../../src/templates/tutorials/list.component.jsx" /* webpackChunkName: "component---src-templates-tutorials-list-component-jsx" */),
  "component---src-templates-tutorials-post-component-jsx": () => import("./../../../src/templates/tutorials/post.component.jsx" /* webpackChunkName: "component---src-templates-tutorials-post-component-jsx" */)
}

