/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/assets/scss/index.scss';

export const onRouteUpdate = ({ location, prevLocation }) => {
    const newUrl = location.pathname;
    try {
        if (window.gtag) {
            window.gtag('event', 'page_view', {
                page_path: newUrl
            });
        }
    } catch (e) {
        console.log(e.message);
    }
};

export const onServiceWorkerUpdateReady = () => window.location.reload();
